<step-tracker [stepIdx]="5" [lastStep]="6">
  <div class="row">
    <div class="col">
      <h2 class="chalmers-h2">{{ "ubs6-header" | translate }}</h2>
      <h3 class="chalmers-h3">{{ "ubs6-header1" | translate }}</h3>
      <p>
        {{ "ubs6-info1" | translate }}
      </p>
      <h3 class="chalmers-h3">{{ "ubs6-header2" | translate }}</h3>
      <p>
        {{ "ubs6-info2" | translate }}
      </p>
      <h3 class="chalmers-h3">{{ "ubs6-header3" | translate }}</h3>
      <p>
        {{ "ubs6-info3" | translate }}
      </p>
      <p>
        <a href="{{ 'ubs6-link-faq-url' | translate }}" target="_blank">{{ "ubs6-link-faq" | translate }}</a>
      </p>
      <h3 class="chalmers-h3">{{ "ubs6-header4" | translate }}</h3>
      <p>
        {{ "ubs6-info4" | translate }}
      </p>
      <p>
        <a class="btn btn-success chalmers-mr-2" role="button" href="/ub">{{ "ubs6-create-new-application" | translate }}</a>
      </p>
      <p>
        {{ "ubs6-info4-2" | translate }}
      </p>
    </div>
  </div>
  <div class="row chalmers-buttons">
    <div class="col">
    </div>
  </div>
</step-tracker>
