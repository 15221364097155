<step-tracker [stepIdx]="3" [lastStep]="7">
  <div class="row">
    <div class="col-12">
      <h2 class="chalmers-h2">{{ "s4-header" | translate }}</h2>
      <p>{{ "s4-info" | translate }}</p>
    </div>
  </div>
  <div class="row" >
    <div class="col-12">
      <h3 class="chalmers-h3">{{ "s4-header1" | translate }}</h3>
      <p>{{ "s4-info1" | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h3 class="chalmers-h3">{{ "s4-header2" | translate }}</h3>
      <p>{{ "s4-info2" | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
        <a href="{{ 's4-link-faq-url' | translate }}" target="_blank">{{ "s4-link-faq" | translate }}</a>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p *ngIf="enableUpload">{{ "s4-attachment" | translate}}</p>
    </div>
  </div>
  <div class="row" style="margin-bottom:15px;" *ngIf="enableUpload">
    <div class="col-md-3">
      <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;" multiple />
      <button type="button" class="btn btn-success" (click)="file.click()">{{ "s4-uploadFile" | translate }}</button>
      <div class="text-danger" *ngIf="noPDF">{{"s4-noPDFFile" | translate}}</div>
    </div>
    <div class="col-md-4">
      <span class="upload" *ngIf="progress > 0">
        {{progress}}%
      </span>
      <span class="upload" *ngIf="message">
        {{message}}
      </span>
    </div>
  </div>
  <div class="row" *ngIf="showUploadedFiles">
    <div class="col-12">
      <h3 class="chalmers-h3">{{ "s4-uploadedFiles" | translate }}</h3>
      <ul class="list-group border">
        <li class="list-group-item align-middle" *ngFor="let file of uploadedFiles">
          {{file}} <button (click)="deleteFile(file)" class="btn btn-danger btn-sm float-right">{{ "s4-Delete" | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
  <div class="row chalmers-buttons">
    <div class="col">
      <a class="btn btn-danger chalmers-button chalmers-mr-2" role="button" [routerLink]="['', { outlets: { authenticated: ['step3'] } }]" routerLinkActive="active">{{ "last" | translate }}</a>
      <a class="btn btn-success chalmers-button" style="color:white" role="button" (click)="onNext()">{{ "next" | translate }}</a>
    </div>
  </div>
</step-tracker>

